import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Mission = () => (
  <Section id="missie">
    <StyledContainer>
      <SectionTitle>Missie</SectionTitle>
      <MissionText>
        In ons dagelijkse leven is het moeilijk om belangrijke gebeurtenissen voor de mensen om ons heen te onthouden, terwijl het juist enorm waardevol is om mensen aandacht te geven.<br/><br />
        Met ThinkYou willen we helpen om te denken aan de mensen om je heen en hen te laten weten dat je aan ze denkt.</MissionText>
    </StyledContainer>
  </Section>
)

export default Mission

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const MissionText = styled.h5`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`
