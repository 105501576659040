import React from "react"
import styled from "styled-components"
import GooglePlay from "../googleplay"
import AppleStore from "../applestore"


import { Container, Section } from "../global"

const GetStarted = () => (
  <StyledSection>
    <GetStartedContainer>
      <GetStartedTitle>Download de app en word socialer</GetStartedTitle>
      <FeaturesGrid>
        <FeatureItem>
          <a href="https://play.google.com/store/apps/details?id=care.thinkyou.app"><GooglePlay /></a>
        </FeatureItem>
        <FeatureItem>
          <a href="https://apps.apple.com/nl/app/thinkyou/id1528346879"><AppleStore /></a>
        </FeatureItem>
        </FeaturesGrid>
    </GetStartedContainer>
  </StyledSection>
)

export default GetStarted

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`
const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`